<!-- 拣货 -->
<template>
  <div class="page">
    <div class="pageTop" v-if="!disblesImg">
      <div class="top">
        <Form inline class="formMarginBtm20">
          <FormItem>
            <span>发货仓库：</span>
            <Select id="sstj1" class="iviewIptWidth250" v-model="warehouse_id" filterable clearable @on-change="wareChange">
              <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="button" :label-width="10">
            <span id="glxsdd" class="longTextBtn finger btnReset" @click="openModal(1)">关联销售订单</span>
            <span id="glthtzd" class="longTextBtn finger btnAdd marginLeft20" @click="openModal(2)">关联退货通知单</span>
          </FormItem>
        </Form>
      </div>
      <div class="btm">
        <div class="barcode">
          <p><span class="fl">条形码</span> <span id="txm_search" class="pageBtn finger btnSure fr" @click="queryCode">查询</span></p>
          <Input id="txm_input" type="textarea" placeholder="请输入带入库条形码，多个产品换行显示" class="textarea" v-model="codeStr"></Input>
        </div>
        <div class="btmRight">
          <div class="checkList">
            <p>
              产品清单<span style="margin-left: 20px;" v-if="customerName != ''">{{ customerName }}</span>
              <span id="zdjh_btn" class="longTextBtn finger btnSure marginLeft20 fr" @click="automaticPicking">自动拣货</span>
            </p>
            <Table :columns="checkColumns" :data="checkData" border></Table>
            <div v-if="checkData.length == 0" class="summary picking">暂未选择销售订单/退货通知单</div>
          </div>
          <p>待出库明细清单<span id="dtbt_sz" class="pageBtn finger btnSure marginLeft20 fr" @click="setupStatus = true">设置</span></p>
          <TableCom :rowClass="rowClassName" :productList="listColumns" :productData="listData" :option_page="option_page" border :height="listData.length == 0 ? 0 : 500">
            <template slot-scope="{ row, index }" slot="bar_code">
              <i-input placeholder="" :value="row.bar_code" @on-change="changeIpt($event, 'bar_code', index)" readonly></i-input>
            </template>
            <template slot-scope="{ row, index }" slot="batch_number">
              <i-input placeholder="请输入" :value="row.batch_number" @on-change="changeIpt($event, 'batch_number', index)"></i-input>
            </template>
            <template slot-scope="{ row, index }" slot="serial_number">
              <i-input placeholder="请输入" :value="row.serial_number" @on-change="changeIpt($event, 'serial_number', index)"></i-input>
            </template>
            <template slot-scope="{ row, index }" slot="quantity">
              <!--              <i-input placeholder="请输入" type="number" :value="row.quantity" @on-change="changeIpt1($event, 'quantity', index, row.product_model_name, row.product_name)"></i-input>-->
              <InputNumber :max="999999" :min="0" :precision="0" v-model="row.quantity" :active-change="false" @on-change="changeIpt1($event, 'quantity', index, row.product_model_code, row.product_name, row.order_detail_id)"></InputNumber>
            </template>
            <template slot-scope="{ row, index }" slot="unit_price">
              <!--              <i-input placeholder="请输入" type="number" :value="row.unit_price" maxlength="8" @on-change="changeIpt1($event, 'unit_price', index)"></i-input>-->
              <InputNumber :max="999999999.99" :min="0" :precision="2" v-model="row.unit_price" :active-change="false" style="width: 100px;" @on-change="changeIpt1($event, 'unit_price', index)"></InputNumber>
            </template>
            <!-- production_date -->
            <template slot-scope="{ row, index }" slot="productionDate">
              <Input @on-blur="dataBlur($event, true)" @on-change="changeIpt($event, 'production_date', index)" type="text" :value="row.production_date" placeholder="请输入" style="width: 150px;" />
              <!-- <DatePicker :transfer="true" placement="bottom-end" :editable="true" placeholder="请选择" type="date" format="yyyy-MM-dd" @on-change="timeChange($event, index)" :value="row.valid_period"></DatePicker> -->
            </template>
            <template slot-scope="{ row, index }" slot="validity">
              <Input @on-blur="dataBlur($event)" @on-change="changeIpt($event, 'valid_period', index)" type="text" :value="row.valid_period" placeholder="请输入" style="width: 150px;" />
              <!-- <DatePicker :transfer="true" placement="bottom-end" :editable="true" placeholder="请选择" type="date" format="yyyy-MM-dd" @on-change="timeChange($event, index)" :value="row.valid_period"></DatePicker> -->
            </template>
            <template slot-scope="{ row, index }" slot="do">
              <div @click="deleteRow(row, index)"><span :id="'dckmx_sc' + index" class="finger color389">删除</span></div>
            </template>
          </TableCom>
          <!-- <div style="position: relative; border: 1px solid #e8eaec; height: 450px; border-top: none !important;" class="clearfix noData" v-if="listData.length == 0">
            <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
          </div> -->
          <div class="totalNumber">
            <div class="item">
              总数量:<span class="color389 marginRight20">{{ TotalQuantity }}</span>
            </div>
            <div class="item">
              总金额:<span class="color389 marginRight20">￥ {{ TotalMoney }}</span>
            </div>
          </div>
          <div class="tipsDiv">
            <div class="tipsFl">备注</div>
            <div class="tipsFr">
              <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model="postFrom.note"></i-input>
            </div>
          </div>
          <div class="button">
            <span id="qr" class="longTextBtn finger btnSure marginLeft20" @click="surePost">确认</span>
          </div>
        </div>
      </div>
    </div>
    <div class="disableImage" v-if="disblesImg">
      <div class="disableDiv">
        <img src="../../assets/images/disableImg.png" alt="" />
        <span>当月已关账，不可操作库存</span>
      </div>
    </div>
    <!--    弹窗选择数据-->
    <Modal v-model="chooseStatus" label-position="left" width="1200" :footer-hide="true" class-name="vertical-center-modal" title="产品列表">
      <div v-if="chooseStatus">
        <Table :columns="chooseColumns" :data="chooseList" border height="500" @on-selection-change="selectChange" highlight-row :row-class-name="rowClassName"></Table>
        <div class="clearfix" style="margin-top: 20px;">
          <span id="tcxz_qr" class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
        </div>
      </div>
    </Modal>
    <!-- 销售订单 -->
    <Modal v-model="sales" @on-cancel="cancelQueryModal1()" :mask-closable="false" width="80" class="modal" :title="text">
      <div>
        <Form inline class="formMarginBtm20">
          <FormItem>
            <span>订单号：</span>
            <i-input id="xsdd_sstj1" class="iviewIptWidth200 marginRight23" placeholder="请输入" v-model="queryModal1.order_code"></i-input>
          </FormItem>
          <FormItem>
            <span>下单时间：</span>
            <DatePicker id="xsdd_sstj2" :editable="false" type="daterange" placement="bottom-end" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth200 marginRight23" @on-change="changeTime($event, 1)"></DatePicker>
          </FormItem>
          <FormItem>
            <span>客户名称：</span>
            <Select id="xsdd_sstj3" class="iviewIptWidth200 marginRight23" v-model="supplierIndex1" clearable>
              <Option v-for="(item, index) in clientNameList" :value="index" :key="index">{{ item.customer_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>订单状态：</span>
            <Select id="xsdd_sstj4" class="iviewIptWidth200 marginRight23" v-model="queryModal1.order_status" clearable>
              <Option v-for="(item, index) in orderList" :value="item.order_status" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>产品编码：</span>
            <i-input placeholder="请输入" class="iviewIptWidth200" v-model="queryModal1.product_model_code"></i-input>
          </FormItem>
          <FormItem>
            <span>产品名称：</span>
            <!-- <Select class="iviewIptWidth200" clearable filterable placeholder="请选择" v-model="queryModal1.product_name">
              <Option :key="index" :value="item.name" v-for="(item, index) in productListSeclect">{{ item.name }}</Option>
            </Select> -->
            <Select class="iviewIptWidth200 marginRight23" clearable filterable placeholder="请选择" v-model="queryModal1.product_name" @on-change="productChange1">
              <Option :key="index" :value="item.product_name" v-for="(item, index) in productListSeclect">{{ item.product_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>规格型号/SKU：</span>
            <Select class="iviewIptWidth200" multiple filterable clearable v-model="queryModal1.model_name" :max-tag-count="queryModal1.model_name.length == 1 ? 1 : 0">
              <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name">
                <span>{{ item.model_name }}</span>
                <!-- <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.product_model_code }}</span> -->
              </Option>
            </Select>
          </FormItem>
          <FormItem class="button" :label-width="10">
            <span id="xsdd_cx" class="pageBtn finger btnSure" @click="queryData(1)">查询</span>
          </FormItem>
        </Form>
        <table-com class="table" :productList="salesColumns" :productData="salesData" :pages="queryModal1" :total="total" totalText="条记录" @change-page="changePage($event, 1)" height="salesData.length > 0 ? 500 : 0">
          <template slot-scope="{ row, index }" slot="product_name_array">
            <Poptip trigger="hover" :content="row.product_name_array">
              <span>{{ row.product_name_array.split(',').splice(0, 2).join(',') }}</span>
            </Poptip>
          </template>
        </table-com>
      </div>
      <div slot="footer"></div>
    </Modal>
    <!-- 产品列表 -->
    <Modal v-model="returnStatus" @on-cancel="cancelQueryModal2()" :mask-closable="false" width="80" class="modal" :title="text">
      <div>
        <Form inline class="formMarginBtm20">
          <FormItem>
            <span>通知单号：</span>
            <i-input id="cplb_sstj1" class="iviewIptWidth200 marginRight23" placeholder="请输入" v-model="queryModal2.order_number"></i-input>
          </FormItem>
          <FormItem>
            <span>下单时间：</span>
            <DatePicker id="cplb_sstj2" :editable="false" type="daterange" placement="bottom-end" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth200 marginRight23" @on-change="changeTime($event, 2)"></DatePicker>
          </FormItem>
          <FormItem>
            <span>供应商：</span>
            <Select id="cplb_sstj3" class="iviewIptWidth200" v-model="queryModal2.supplier_id" clearable>
              <Option v-for="(item, index) in supplierList" :value="item.supplier_id" :key="index">{{ item.supplier_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>产品编码：</span>
            <i-input placeholder="请输入" class="iviewIptWidth200" v-model="queryModal2.product_model_code"></i-input>
          </FormItem>
          <FormItem>
            <span>产品名称：</span>
            <!-- <Select class="iviewIptWidth200" clearable filterable placeholder="请选择" v-model="queryModal2.product_name">
              <Option :key="index" :value="item.name" v-for="(item, index) in productListSeclect">{{ item.name }}</Option>
            </Select> -->
            <Select class="iviewIptWidth200" clearable filterable placeholder="请选择" v-model="queryModal2.product_name" @on-change="productChange2">
              <Option :key="index" :value="item.product_name" v-for="(item, index) in productListSeclect">{{ item.product_name }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <span>规格型号/SKU：</span>
            <Select class="iviewIptWidth200" multiple filterable clearable v-model="queryModal2.model_name" :max-tag-count="queryModal2.model_name.length == 1 ? 1 : 0">
              <Option v-for="(item, index) in modelList" :value="item.model_name" :key="index" :label="item.model_name">
                <span>{{ item.model_name }}</span>
                <!-- <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.product_model_code }}</span> -->
              </Option>
            </Select>
          </FormItem>
          <FormItem class="button" :label-width="10">
            <span id="cplb_cx" class="pageBtn finger btnSure" @click="queryData(2)">查询</span>
          </FormItem>
        </Form>
        <table-com class="table" :productList="returnColumns" :productData="returnData" :pages="queryModal2" :total="total2" totalText="条记录" @change-page="changePage($event, 2)" height="returnData.length > 0 ? 500 : 0">
          <template slot-scope="{ row, index }" slot="product_list">
            <Poptip trigger="hover" :content="row.product_list">
              <span>{{ row.product_list.split(',').splice(0, 2).join(',') }}</span>
            </Poptip>
          </template>
        </table-com>
      </div>
      <div slot="footer"></div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="lastPost">
      <slot>
        <div class="moreNum" v-if="moreNum">
          <span style="color: rgb(250, 62, 59);">{{ moreNum }}</span
          >个产品拣货数量超出订单数量
        </div>
        <div class="unmoreNum" v-if="unmoreNum">
          <span style="color: rgb(46, 169, 9);">{{ unmoreNum }}</span
          >个产品拣货数量低于订单数量
        </div>
      </slot>
    </tips-component>
    <!--    审核-->
    <Modal v-model="modal1" label-position="left" width="400" :footer-hide="true" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>拣货数量小于订单数量，是否确认拣货</p>
        <div>
          <span id="sh_qr" class="pageBtn finger btnSure" @click="lastPost">确定</span>
          <span id="sh_qx" class="pageBtn finger btnCancle" @click="modal1 = false">取消</span>
        </div>
      </div>
    </Modal>
    <!-- 自动拣货弹窗 -->
    <Modal v-model="model3" label-position="left" width="1200" :footer-hide="true" class-name="vertical-center-modal" title="产品列表">
      <Table :columns="checkColumns2" height="450" :data="checkData2" border>
        <template slot-scope="{ row, index }" slot="picking_quantity">
          <!--              <i-input placeholder="请输入" type="number" :value="row.quantity" @on-change="changeIpt1($event, 'quantity', index, row.product_model_name, row.product_name)"></i-input>-->
          <InputNumber class="onlyonce" :id="'zdjh' + index" :max="999999" :min="0" :precision="0" v-model="row.picking_quantity" :active-change="false" @on-change="changeIpt2($event, index)"></InputNumber>
        </template>
      </Table>
      <div class="clearfix" style="margin-top: 20px;">
        <span id="zdjh_qr" class="pageBtn finger btnSure fr" @click="automaticjh">确定</span>
      </div>
    </Modal>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="comfirStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否更换发货仓库？</p>
        <div>
          <span id="qh_qr" class="pageBtn finger btnSure" @click="sureChangewarehouseId">确定</span>
          <span id="qh_qx" class="pageBtn finger btnCancle" @click="cancleChangewarehouseId">取消</span>
        </div>
      </div>
    </Modal>
    <!-- 动态表头弹窗 -->
    <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
  </div>
</template>

<script>
import TableSetup from '@/components/tableSetup'
import TableCom from '@/components/table'
import TipsComponent from '@/components/tipsComponent'
import { mapState } from 'vuex'
export default {
  name: 'orderPicking',
  components: {
    TableSetup,
    TableCom,
    TipsComponent,
  },
  computed: {
    ...mapState(['clientNameList']),
    // 计算金额数量
    TotalQuantity() {
      let total = 0
      this.listData.forEach(item => {
        total = total + item.quantity
      })
      return total
    },
    // 总金额
    TotalMoney() {
      let total = 0
      this.listData.forEach(item => {
        total = total + item.unit_price * item.quantity
      })
      return total.toFixed(2)
    },
  },
  inject: ['reload'],
  data() {
    return {
      productListSeclect: [],
      deleteObjArr: [],
      qiegeFlag: false,
      disblesImg: false,
      comfirStatus: false,
      List: [],
      Number: null,
      customerName: '',
      // type=1 是关联销售订单 type=2是退货通知单
      type: 0,
      titleList: [],
      option_page: '15',
      setupStatus: false,
      modal1: false,
      checkColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 200,
        },
        {
          title: '订单数量',
          key: 'quantity',
          align: 'center',
          width: 150,
        },
        {
          title: '拣货数量',
          key: 'pickNum',
          align: 'center',
          width: 150,
        },
        // {
        //   title: '已拣货数量',
        //   key: 'pickNum_old',
        //   align: 'center',
        //   width: 150,
        // },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          align: 'center',
          width: 250,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                    marginRight: '40px',
                  },
                  class: 'cpqd_yjh' + param.index,
                  on: {
                    click: () => {
                      this.readyRow(param.row, param.index)
                    },
                  },
                },
                '预拣货'
              ),
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  class: 'cpqd_kccx' + param.index,
                  on: {
                    click: () => {
                      this.inventoryQuery(param.row)
                    },
                  },
                },
                '库存查询'
              ),
            ])
          },
        },
      ],
      model3: false,
      checkColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          // minWidth: 250,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 200,
        },
        {
          title: '订单数量',
          key: 'quantity',
          align: 'center',
          width: 150,
        },
        {
          title: '库存余量',
          key: 'inventory_quantity',
          align: 'center',
          width: 150,
        },
        {
          title: '拣货数量',
          slot: 'picking_quantity',
          align: 'center',
          width: 150,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 100,
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
        },
      ],
      checkData: [],
      checkData2: [],
      moreNum: 0,
      unmoreNum: 0,
      postStatus: false,
      supplierList: [],
      text: '销售订单产品列表',
      deleteStatus: false,
      chooseStatus: false,
      chooseSupplier: '',
      chooseList: [],
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 120,
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          width: 120,
        },
        {
          title: '序列号',
          key: 'serial_number',
          align: 'center',
          width: 80,
        },
        {
          title: '数量',
          key: 'inventory_quantity',
          align: 'center',
          width: 70,
        },
        {
          title: '有效期',
          key: 'validPeriod',
          align: 'center',
          width: 100,
        },
        {
          title: '所属仓库',
          key: 'warehouse_name',
          align: 'center',
          width: 120,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          width: 150,
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
          width: 150,
        },
      ],
      warehouse_id: '',
      note: '',
      supplierIndex: -1,
      supplierIndex1: -1,
      supplierIndex2: -1,
      // 销售订单
      sales: false,
      salesColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '下单时间',
          key: 'creatTime',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '订单号',
          key: 'order_code',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '产品',
          slot: 'product_name_array',
          align: 'left',
          minWidth: 180,
        },
        {
          title: '数量',
          key: 'order_number',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
        },
        {
          title: '金额',
          key: 'product_amount',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
          render: (h, param) => {
            return h('span', this.$utils.formatMoney(param.row.product_amount, true))
          },
        },
        {
          title: '客户名称',
          key: 'customer_name',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '备注',
          key: 'remark',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '订单状态',
          key: 'order_status_str',
          align: 'center',
          minWidth: 90,
        },
        {
          title: '操作',
          align: 'center',
          width: 128,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  class: 'glxxdd_qr' + param.index,
                  on: {
                    click: () => {
                      this.guanlianhang={
                        selectType:1,
                        ...param.row
                      }
                      this.chooseSaleOrder(param.row)
                    },
                  },
                },
                '确认'
              ),
            ])
          },
        },
      ],
      salesData: [],
      returnStatus: false,
      returnData: [],
      returnColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '下单时间',
          key: 'creatTime',
          align: 'center',
        },
        {
          title: '通知单号',
          key: 'order_number',
          align: 'center',
        },
        {
          title: '产品',
          slot: 'product_list',
          align: 'left',
          minWidth: 180,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 100,
          maxWidth: 130,
          render: (h, param) => {
            return h('span', '¥' + param.row.total_amount)
          },
        },
        {
          title: '供应商',
          key: 'supplier_name',
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 128,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  class: 'glthtzd_qr' + param.index,
                  on: {
                    click: () => {
                      this.guanlianhang={
                        selectType:2,
                        ...param.row
                      }
                      this.chooseReturnOrder(param.row)
                    },
                  },
                },
                '确认'
              ),
            ])
          },
        },
      ],
      total: 1,
      orderList: [
        { order_status: 1, name: '待发货' },
        { order_status: 2, name: '部分发货' },
      ],
      queryModal1: {
        product_model_code: '',
        product_name: '',
        page: 1,
        rows: 10,
        order_code: '',
        customer_id: '',
        customer_type: '',
        order_time_begin: '',
        order_time_end: '',
        order_status: '',
        model_name:''
      },
      queryModal2: {
        product_model_code: '',
        product_name: '',
        page: 1,
        rows: 10,
        order_number: '',
        supplier_id: '',
        start_time: '',
        end_time: '',
        purchase_return_state: '2',
        related_picking: '1',
        picking_page: '1',
        model_name:''
      },
      total2: 0,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          fixed: 'left',
          width: 65,
        },
        {
          title: '产品编码',
          key: 'product_model_code',
          align: 'center',
          width: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          width: 250,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          width: 220,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 120,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 120,
        },
        {
          title: '生产厂家',
          key: 'factory_name',
          align: 'center',
          width: 250,
        },
        {
          title: '条形码',
          slot: 'bar_code',
          align: 'center',
          width: 350,
        },
        {
          title: '批号',
          slot: 'batch_number',
          align: 'center',
          width: 150,
        },
        {
          title: '序列号',
          slot: 'serial_number',
          align: 'center',
          width: 150,
        },
        {
          title: '数量',
          slot: 'quantity',
          align: 'center',
          width: 118,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 100,
        },
        {
          title: '销售单价',
          slot: 'unit_price',
          align: 'center',
          width: 118,
        },
        {
          title: '生产日期',
          slot: 'productionDate',
          align: 'center',
          width: 150,
        },
        {
          title: '有效期',
          slot: 'validity',
          align: 'center',
          width: 150,
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 80,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.row, param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      storeList: [],
      queryFrom: {},
      hadSelectList: [],
      postFrom: {
        warehouse_id: '',
        line_info: [],
        note: '',
        warehouse_name: '',
      },
      codeStr: '',
      deleteIndex: 0,
      deleteObj: {},
      flag: true,
      gltype: '',
      checkDataClone: [],
      modelList:[], //产品规格列表
      guanlianhang:{}
    }
  },
  mounted() {
    this.queryOption()
    this.querySupplierSelect()
    this.queryStoreList()
    this.inventoryLook()
    this.queryProduct()
  },
  activated() {
    this.inventoryLook()
  },
  methods: {
    queryProduct(id = null, subId = null) {
      // this.$http.get(this.$api.getProductNameSelect, { product_name: subId, warehouse_id: this.warehouse_id }, false).then(res => {
      //   this.productListSeclect = res.data
      // })
      this.$http.get(this.$api.productShow, {search_type:'1'}, false).then(res => {
        console.log(res);
        this.productListSeclect = res.data
      })
    },
    // 新增界面禁用功能
    inventoryLook() {
      this.$http.get(this.$api.inventoryLook, {}).then(res => {
        if (res.data === 1) {
          this.disblesImg = true
        }
      })
    },
    // 拣货发货仓库转换
    pickingTranfrom(e) {
      if (!e) {
        this.postFrom.warehouse_name = ''
        this.postFrom.warehouse_id = ''
        return
      }
      for (let i = 0; i < this.storeList.length; i++) {
        if (e == this.storeList[i].warehouse_id) {
          this.postFrom.warehouse_name = this.storeList[i].warehouse_name
          this.postFrom.warehouse_id = this.storeList[i].warehouse_id
        }
      }
    },
    // 取消更换仓库
    cancleChangewarehouseId() {
      this.warehouse_id = this.postFrom.warehouse_id
      this.comfirStatus = false
      this.pickingTranfrom(this.warehouse_id)
    },
    // 确认更换发货仓库
    sureChangewarehouseId() {
      this.listData = []
      // 还原拣货数量
      this.checkData.forEach(item => {
        item.pickNum = item.picking_quantity
      })
      this.comfirStatus = false
      this.pickingTranfrom(this.warehouse_id)
    },
    // 发起仓库发生改变
    wareChange(e) {
      this.warehouse_id = e
      if (this.listData.length > 0) {
        this.comfirStatus = true
        return null
      } else {
        this.pickingTranfrom(e)
      }
    },
    // 自动拣货数据
    automaticPicking() {
      if (!this.warehouse_id) {
        this.$Message.warning('请选择发货仓库')
        return null
      }
      let param
      if (!this.qiegeFlag) {
        this.checkData2 = []
        // 第一次进来切割数组/后面的不再切割
        let arr = this.checkData.filter((item, index) => {
          if (item.pickNum != item.quantity) {
            return item
          }
        })
        let arr2 = JSON.parse(JSON.stringify(arr))
        arr2.forEach(item => {
          item.picking_quantity = 0
        })
        this.checkData2 = JSON.parse(JSON.stringify(arr2))
        if (this.checkData2.length == 0) {
          this.$Message.warning('无可拣货产品')
          this.qiegeFlag = false
          return
        }
        // 获取自动拣货的仓库库存量
        param = {
          warehouse_id: this.warehouse_id,
          line_info: this.checkData2,
        }
        this.qiegeFlag = true
      } else {
        if (this.checkData2.length == 0) {
          this.$Message.warning('无可拣货产品')
          return
        }
        // 获取自动拣货的仓库库存量
        param = {
          warehouse_id: this.warehouse_id,
          line_info: this.checkData2,
        }
      }
      this.$http.post(this.$api.automaticPicking, param, true).then(result => {
        if (!result.status) {
          return
        }
        this.checkData2 = result.data
      })
      // 自动拣货的时候，如果下面表格有数量，直接赋值到此处
      if (this.listData.length > 0 && this.deleteObjArr.length == 0) {
        for (let j = 0; j < this.checkData2.length; j++) {
          this.$set(this.checkData2[j], 'picking_quantity', 0)
          for (let i = 0; i < this.listData.length; i++) {
            if (this.listData[i].product_name == this.checkData2[j].product_name && this.listData[i].product_model_code == this.checkData2[j].product_model_code && this.listData[i].order_detail_id == this.checkData2[j].order_detail_id) {
              this.$set(this.checkData2[j], 'picking_quantity', (this.checkData2[j].picking_quantity ? this.checkData2[j].picking_quantity : 0) + this.listData[i].quantity)
            }
          }
        }
      }
      if (this.deleteObjArr.length > 0 && this.listData.length > 0) {
        for (let i = 0; i < this.deleteObjArr.length; i++) {
          for (let j = 0; j < this.checkData2.length; j++) {
            if (this.deleteObjArr[i].product_name == this.checkData2[j].product_name && this.deleteObjArr[i].product_model_code == this.checkData2[j].product_model_code && this.deleteObjArr[i].order_detail_id == this.checkData2[j].order_detail_id) {
              this.$set(this.checkData2[j], 'picking_quantity', this.checkData2[j].picking_quantity - this.deleteObjArr[i].quantity)
            }
          }
        }
      }
      if (this.deleteObjArr.length > 0 && this.listData.length == 0) {
        for (let i = 0; i < this.deleteObjArr.length; i++) {
          for (let j = 0; j < this.checkData2.length; j++) {
            if (this.deleteObjArr[i].product_name == this.checkData2[j].product_name && this.deleteObjArr[i].product_model_code == this.checkData2[j].product_model_code && this.deleteObjArr[i].order_detail_id == this.checkData2[j].order_detail_id) {
              this.$set(this.checkData2[j], 'picking_quantity', 0)
            }
          }
        }
      }
      this.deleteObjArr = []
      this.model3 = true
    },
    // 自动拣货按钮
    automaticjh() {
      if (!this.warehouse_id) {
        this.$Message.warning('请选择发货仓库')
        return null
      }
      this.deleteObjArr = []
      let arr = this.checkData2.filter(item => {
        if (item.picking_quantity) {
          return item
        }
      })
      if (arr.length == 0) {
        this.$Message.warning('请输入订单数量')
        return
      }
      if (!this.model3) return // 限制连续点击
      this.model3 = false

      let obj = {
        warehouse_id: this.warehouse_id,
        line_info: arr,
        picking_type: this.gltype,
      }
      for (let i = 0; i < this.listData.length; i++) {
        for (let j = 0; j < this.checkData.length; j++) {
          if (this.listData[i].product_name == this.checkData[j].product_name && this.listData[i].product_model_code == this.checkData[j].product_model_code && this.listData[i].order_detail_id == this.checkData[j].order_detail_id) {
            this.$set(this.checkData[j], 'pickNum', this.checkData[j].pickNum - this.listData[i].quantity)
          }
        }
      }
      this.listData = []
      this.checkDataClone = JSON.parse(JSON.stringify(this.checkData))
      this.$http.put(this.$api.automaticPicking, obj, false).then(res => {
        res.data.forEach(item => {
          this.readyRow(item, '', true)
        })
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < this.checkData.length; j++) {
            if (res.data[i].product_name == this.checkData[j].product_name && res.data[i].product_model_code == this.checkData[j].product_model_code && res.data[i].order_detail_id == this.checkData[j].order_detail_id) {
              this.$set(this.checkData[j], 'pickNum', res.data[i].quantity + this.checkData[j].pickNum)
            }
          }
        }
      })
    },
    // 有效期小于180标黄
    rowClassName(row, index) {
      if (row.warning) return 'orginRow'
      return ''
    },
    // 对输入时间进行检测
    dataBlur(e, type) {
      if (!e.target.value && type) return
      let res = this.$utils.dataType(e.target.value)
      if (res.code) {
        this.$Message.warning(res.text)
      }
    },
    sureSetup(val) {
      this.queryOption()
    },
    // 获取表格表头
    queryOption() {
      this.$http
        .get(this.$api.systemOptionItem, { option_page: this.option_page })
        .then(res => {
          this.titleList = res.data.enable
          localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
          for (let i = 0; i < this.titleList.length; i++) {
            if (this.titleList[i].show) {
              this.titleList[i].key = this.titleList[i].data_col
              this.titleList[i].width = this.titleList[i].column_width
              this.titleList[i].align = 'center'
              this.titleList[i].resizable = true

              // type=1 是关联销售订单 type=2是退货通知
              if (this.titleList[i].title === '条形码' && this.type === 1) {
                this.titleList[i].slot = 'bar_code'
              }
              if (this.titleList[i].title === '批号' && this.type === 1) {
                this.titleList[i].slot = 'batch_number'
              }
              if (this.titleList[i].title === '序列号' && this.type === 1) {
                this.titleList[i].slot = 'serial_number'
              }
              if (this.titleList[i].title === '数量' && this.type === 1) {
                this.titleList[i].slot = 'quantity'
              }
              if (this.titleList[i].title === '销售/采购退货单价' && this.type === 1) {
                this.titleList[i].slot = 'unit_price'
              }
              if (this.titleList[i].title === '生产日期' && this.type === 1) {
                this.titleList[i].slot = 'productionDate'
              }
              if (this.titleList[i].title === '有效期' && this.type === 1) {
                this.titleList[i].slot = 'validity'
              }

              // 退货通知处理 ????????
              if (this.titleList[i].title === '条形码' && this.type === 2) {
                this.titleList[i].slot = 'bar_code'
              }
              if (this.titleList[i].title === '批号' && this.type === 2) {
                this.titleList[i].slot = 'batch_number'
              }
              if (this.titleList[i].title === '序列号' && this.type === 2) {
                this.titleList[i].slot = 'serial_number'
              }
              // 新增
              if (this.titleList[i].title === '数量' && this.type === 2) {
                this.titleList[i].slot = 'quantity'
              }
              if (this.titleList[i].title === '生产日期' && this.type === 2) {
                this.titleList[i].slot = 'productionDate'
              }
              if (this.titleList[i].title === '有效期' && this.type === 2) {
                this.titleList[i].slot = 'validity'
              }
            }
          }
          this.listColumns = []
          this.listColumns.unshift({
            title: '序号',
            type: 'index',
            align: 'center',
            width: 60,
          })
          let doTitle = {
            title: '操作',
            align: 'center',
            slot: 'do',
            width: 100,
            fixed: 'right',
          }
          this.listColumns = [...this.listColumns, ...this.titleList, doTitle]
          this.listColumns = JSON.parse(JSON.stringify(this.listColumns))
        })
        .then(() => {
          // this.queryStoreList()
        })
    },
    handleProcess(){
      let arr = [] // 暂存数据
      // 处理时间
      this.hadSelectList.forEach((item, index) => {
        item.licence_valid_period = item.licence_valid_period ? this.$moment.unix(item.licence_valid_period).format('YYYY-MM-DD') : null
        item.production_date = item.production_date ? this.$moment.unix(item.production_date).format('YYYY-MM-DD HH:mm:ss') : null
        // 判断是录入的是年月还是年月日
        item.production_date = item.production_date ? this.$utils.Timetransformation(item.production_date).replace(/-/gi, '') : null
        item.production_license_valid_period = item.production_license_valid_period ? this.$moment.unix(item.production_license_valid_period).format('YYYY-MM-DD') : null
        item.production_record_date = item.production_record_date ? this.$moment.unix(item.production_record_date).format('YYYY-MM-DD') : null
        if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
          item.business_license_valid_period = item.business_license_valid_period ? this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') : null
        } else if (item.business_license_valid_period && this.$moment.unix(item.business_license_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
          item.business_license_valid_period = '长期'
        } else {
          item.business_license_valid_period = null
        }
        if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
          item.record_valid_period = item.record_valid_period ? this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') : null
        } else if (item.record_valid_period && this.$moment.unix(item.record_valid_period).format('YYYY-MM-DD') == '9999-12-31') {
          item.record_valid_period = '长期'
        } else {
          item.record_valid_period = null
        }
        item.return_time = item.return_time ? this.$moment.unix(item.return_time).format('YYYY-MM-DD') : null
        item.receive_time = item.receive_time ? this.$moment.unix(item.receive_time).format('YYYY-MM-DD') : null
        item.accept_time = item.accept_time ? this.$moment.unix(item.accept_time).format('YYYY-MM-DD') : null
        item.rec_time = item.rec_time ? this.$moment.unix(item.rec_time).format('YYYY-MM-DD') : null
        item.order_time = item.order_time ? this.$moment.unix(item.order_time).format('YYYY-MM-DD') : null
        item.picking_time = item.picking_time ? this.$moment.unix(item.picking_time).format('YYYY-MM-DD') : null
        item.delivery_time = item.delivery_time ? this.$moment.unix(item.delivery_time).format('YYYY-MM-DD') : null
      })
      let n = this.Number
      // 当可填数量大于0 当情况，勾选数量以此递减
      if (n > 0) {
        for (var i = 0; i < this.hadSelectList.length; i++) {
          // 如果当前的小于
          if (this.hadSelectList[i].quantity < n) {
            arr.push(this.hadSelectList[i])
            n = n - this.hadSelectList[i].quantity
          } else {
            this.hadSelectList[i].quantity = n
            arr.push(this.hadSelectList[i])
            break
          }
        }
      } else {
        // 可填数量小于等于0， 勾选当数量为0
        for (let i = 0; i < this.hadSelectList.length; i++) {
          this.hadSelectList[i].quantity = 0
          arr.push(this.hadSelectList[i])
        }
      }
      this.hadSelectList = JSON.parse(JSON.stringify(arr))
      if (this.listData.length > 0) {
        // let select = this.listData.map(item => item.product_name + item.model_name + item.item_number + item.serial_number)
        // this.hadSelectList = this.hadSelectList.filter(item => {
        //   return !select.includes(item.product_name + item.model_name + item.item_number + item.serial_number)
        // })
        let select = this.listData.map(item => item.batch_number + item.product_model_code)
        this.hadSelectList = this.hadSelectList.filter(i => {
          return !select.includes(i.batch_number + i.product_model_code)
        })
        this.listData = [...this.listData, ...this.hadSelectList]
      } else {
        this.listData = [...this.hadSelectList]
      }
      this.listData = JSON.parse(JSON.stringify(this.listData))
      for (let i = 0; i < this.hadSelectList.length; i++) {
        for (let j = 0; j < this.checkData.length; j++) {
          if (this.hadSelectList[i].product_name == this.checkData[j].product_name && this.hadSelectList[i].product_model_code == this.checkData[j].product_model_code && this.hadSelectList[i].order_detail_id == this.checkData[j].order_detail_id) {
            this.$set(this.checkData[j], 'pickNum', this.hadSelectList[i].quantity + this.checkData[j].pickNum)
          }
        }
      }
      this.checkData = JSON.parse(JSON.stringify(this.checkData))
      this.chooseStatus = false
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.$Message.warning('请至少选择一个产品再提交')
        return
      }
      if (!this.warehouse_id) {
        this.$Message.warning('请选择发货仓库')
        return
      }
      if (this.hadSelectList.some(item => item.supplier_name !== this.chooseSupplier)) {
        return new Promise((resolve, reject) => {
          this.$Modal.confirm({
              title: '提示',
              content: `<p>与${this.gltype == 1 ? '销售订单' : '采购退货通知单'}供应商不一致，是否继续拣货</p>`,
              onOk: () => {
                this.handleProcess()
              },
              onCancel: () => {
              }
          })
        })
      }
      this.handleProcess()
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list || []
      for (let i = 0; i < this.hadSelectList.length; i++) {
        this.$set(this.hadSelectList[i], 'quantity', this.hadSelectList[i].inventory_quantity)
      }
    },
    // 预拣货
    readyRow(row, index, type) {
      if (!type) {
        this.$set(this.checkData[index], 'pickNum', this.checkData[index].pickNum + row.quantity)
      }
      this.$set(this.listData, this.listData.length, row)
      let length = this.listData.length - 1
      if (type) {
        this.listData[length].autoPicking = true
      }
      // 处理时间
      // 注册证/备案凭证有效期至
      if (this.listData[length].licence_valid_period) {
        this.listData[length].licence_valid_period = this.$moment.unix(this.listData[length].licence_valid_period).format('YYYY-MM-DD')
      }
      // 生产日期
      if (this.listData[length].production_date) {
        this.listData[length].production_date = this.$moment.unix(this.listData[length].production_date).format('YYYY-MM-DD HH:mm:ss')
        this.listData[length].production_date = this.$utils.Timetransformation(this.listData[length].production_date).replace(/-/gi, '')
      }
      // 有效期
      if (this.listData[length].valid_period) {
        this.listData[length].valid_period = this.$moment.unix(this.listData[length].valid_period).format('YYYY-MM-DD HH:mm:ss')
        this.listData[length].valid_period = this.$utils.Timetransformation(this.listData[length].valid_period).replace(/-/gi, '')
      }
      // 生产许可证有效期
      if (this.listData[length].production_license_valid_period) {
        this.listData[length].production_license_valid_period = this.$moment.unix(this.listData[length].production_license_valid_period).format('YYYY-MM-DD')
      }
      // 生产备案日期
      if (this.listData[length].production_record_date) {
        this.listData[length].production_record_date = this.$moment.unix(this.listData[length].production_record_date).format('YYYY-MM-DD')
      }
      // 经营许可证有效期
      if (this.listData[length].business_license_valid_period && this.$moment.unix(this.listData[length].business_license_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
        this.listData[length].business_license_valid_period = this.$moment.unix(this.listData[length].business_license_valid_period).format('YYYY-MM-DD')
      } else {
        this.listData[length].business_license_valid_period = '长期'
      }
      // 经营备案日期
      if (this.listData[length].record_valid_period && this.$moment.unix(this.listData[length].record_valid_period).format('YYYY-MM-DD') != '9999-12-31') {
        this.listData[length].record_valid_period = this.$moment.unix(this.listData[length].record_valid_period).format('YYYY-MM-DD')
      } else {
        this.listData[length].record_valid_period = '长期'
      }
      // 采购/销售退货时间
      if (this.listData[length].return_time) {
        this.listData[length].return_time = this.$moment.unix(this.listData[length].return_time).format('YYYY-MM-DD')
      }
      // 收货时间
      if (this.listData[length].receive_time) {
        this.listData[length].receive_time = this.$moment.unix(this.listData[length].receive_time).format('YYYY-MM-DD')
      }
      // 验收时间
      if (this.listData[length].accept_time) {
        this.listData[length].accept_time = this.$moment.unix(this.listData[length].accept_time).format('YYYY-MM-DD')
      }
      // 入库时间
      if (this.listData[length].rec_time) {
        this.listData[length].rec_time = this.$moment.unix(this.listData[length].rec_time).format('YYYY-MM-DD')
      }
      // 销售/采购退货时间
      if (this.listData[length].order_time) {
        this.listData[length].order_time = this.$moment.unix(this.listData[length].order_time).format('YYYY-MM-DD')
      }
      // 拣货时间
      if (this.listData[length].picking_time) {
        this.listData[length].picking_time = this.$moment.unix(this.listData[length].picking_time).format('YYYY-MM-DD')
      }
      // 出库时间
      if (this.listData[length].delivery_time) {
        this.listData[length].delivery_time = this.$moment.unix(this.listData[length].delivery_time).format('YYYY-MM-DD')
      }
    },
    // 库存查询
    inventoryQuery(row) {
      this.Number = row.quantity - row.pickNum
      let data = {
        // specification_id: row.specification_id,
        product_model_code: row.product_model_code,
        product_id: row.product_id,
        warehouse_id: this.warehouse_id,
      }
      if (row.order_code) {
        data.order_code = row.order_code
        data.order_detail_id = row.order_detail_id
      } else {
        data.purchase_return_id = row.purchase_return_id
        data.id = row.id
      }
      data.picking_page = '1'
      this.hadSelectList = []
      this.$http.get(this.$api.inventoryQuery, data, true).then(res => {
        this.chooseList = res.data
        for (let i = 0; i < this.chooseList.length; i++) {
          this.chooseList[i].validPeriod = this.chooseList[i].valid_period ? this.$moment(this.chooseList[i].valid_period * 1000).format('YYYY-MM-DD') : null
          this.chooseList[i].warning = this.$utils.warningDay(this.chooseList[i].valid_period, 180)
          this.chooseList[i].licence_code = row.licence_code
          this.chooseList[i].product_model_name = this.chooseList[i].model_name
          this.chooseList[i].unit = row.product_unit
          this.chooseList[i].valid_period = this.chooseList[i].valid_period ? this.$moment(this.chooseList[i].valid_period * 1000).format('YYYY-MM-DD HH:mm:ss') : null
          this.chooseList[i].valid_period = this.chooseList[i].valid_period ? this.$utils.Timetransformation(this.chooseList[i].valid_period).replace(/-/gi, '') : null
        }
        if (this.chooseList.length > 0) {
          this.chooseStatus = true
          this.chooseSupplier = row.supplier_name
        } else {
          this.$Message.warning('暂无数据')
        }
      })
    },
    // 新增
    addRow(row, index) {
      row.addStatus = false
      row.bar_code = ''
      row.batch_number = ''
      row.serial_number = ''
      row.quantity = ''
      row.unit_price = ''
      row.valid_period = null
      this.listData.splice(index + 1, 0, row)
      this.listData = JSON.parse(JSON.stringify(this.listData))
    },
    queryCode() {
      let nameArr = []
      if (this.checkData.length == 0) {
        this.$Message.warning('请选择采购单/销售退货通知单')
        return
      }
      this.checkData.forEach(item => {
        nameArr.push(item.product_name + item.model_name)
      })
      nameArr = [...new Set(nameArr)]
      if (!this.codeStr) {
        this.$Message.warning('请输入条形码再查询')
        return
      }
      let arr = this.codeStr.trim().split('\n')
      this.$http.post(this.$api.productSearchBarcode, { barcode_list: arr, type: 'picking' }, true).then(res => {
        this.codeStr = ''
        if (res.data.length == 0) {
          this.$Message.warning('清单中暂无匹配数据')
        } else {
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < this.checkData.length; j++) {
              if (res.data[i].name === this.checkData[j].product_name) {
                if (this.checkData[j].order_code) {
                  res.data[i].order_code = this.checkData[j].order_code
                  res.data[i].order_detail_id = this.checkData[j].order_detail_id
                } else if (this.checkData[j].purchase_return_id) {
                  res.data[i].id = this.checkData[j].id
                  res.data[i].purchase_return_id = this.checkData[j].purchase_return_id
                }
                res.data[i].product_name = this.checkData[j].product_name
                res.data[i].licence_code = this.checkData[j].licence_code
                res.data[i].factory_name = this.checkData[j].factory_name
                res.data[i].unit_price = this.checkData[j].product_unit_price
              }
            }
          }
          let arr = []
          for (let i = 0; i < res.data.length; i++) {
            for (let j = 0; j < this.listData.length; j++) {
              if (res.data[i].name === this.listData[j].product_name && res.data[i].model_name === this.listData[j].model_name && !this.listData[j].hadAdd && !res.data[i].hadAdd) {
                this.$set(this.listData[j], 'hadAdd', true)
                res.data[i].hadAdd = true
                this.listData[j].bar_code = res.data[i].bar_code
                this.listData[j].batch_number = res.data[i].batch_number
                this.listData[j].production_date = res.data[i].production_date ? res.data[i].production_date : this.listData[j].production_date
                this.listData[j].valid_period = res.data[i].valid_period ? res.data[i].valid_period : this.listData[j].valid_period
                this.listData[j].serial_number = res.data[i].serial_number
              } else if (res.data[i].name === this.listData[j].product_name && res.data[i].model_name === this.listData[j].model_name && !this.listData[j].hadAdd && !res.data[i].hadAdd && this.listData[j].serial_number != res.data[i].serial_number) {
                res.data[i].quantity = 1
                res.data[i].product_name = res.data[i].name
                res.data[i].product_model_name = res.data[i].model_name
                this.$set(this.listData[j], 'hadAdd', true)
                res.data[i].hadAdd = true
                let row = res.data[i]
                arr.push(row)
                for (let i = 0; i < this.checkData.length; i++) {
                  if (this.checkData[i].product_name == row.name && this.checkData[i].product_model_code == row.product_model_code) {
                    this.$set(this.checkData[i], 'pickNum', this.checkData[i].pickNum + 1)
                  }
                }
              } else if (res.data[i].name === this.listData[j].product_name && res.data[i].model_name === this.listData[j].model_name && this.listData[j].serial_number == res.data[i].serial_number) {
                res.data[i].hadAdd = true
              }
            }
          }
          for (let i = 0; i < res.data.length; i++) {
            if (!res.data[i].hadAdd && nameArr.includes(res.data[i].product_name + res.data[i].model_name)) {
              res.data[i].quantity = 1
              res.data[i].hadAdd = true
              res.data[i].product_model_name = res.data[i].model_name
              res.data[i].product_name = res.data[i].name
              let row = res.data[i]
              arr.push(row)
              for (let i = 0; i < this.checkData.length; i++) {
                if (this.checkData[i].product_name == row.name && this.checkData[i].product_model_code == row.product_model_code) {
                  this.$set(this.checkData[i], 'pickNum', this.checkData[i].pickNum + 1)
                }
              }
            }
          }
          this.listData = [...this.listData, ...arr]
          this.listData = JSON.parse(JSON.stringify(this.listData))
          this.$Message.success('数据匹配完成')
        }
      })
    },
    timeChange(e, index) {
      this.$set(this.listData[index], 'valid_period', e)
    },
    // 确认提交
    surePost() {
      if (!this.warehouse_id) {
        this.$Message.warning('请选择发货仓库')
        return null
      }
      if (this.listData.length == 0) {
        this.$Message.warning('请至少添加一组产品')
        return null
      }

      for (let i = 0; i < this.listData.length; i++) {
        // if(this.listData[i].warehouse_id != this.warehouse_id) {
        //   this.$Message.warning(`第${i + 1}组产品的所属仓库与选择的发货仓库不一致`)
        //   return
        // }
        if (!this.listData[i].quantity || !this.listData[i].batch_number || !this.listData[i].valid_period) {
          this.$Message.warning(`请完善第${i + 1}组产品的批号/数量/有效期再提交`)
          return
        }
        let resCode = this.$utils.dataType(this.listData[i].valid_period)
        if (resCode.code) {
          this.$Message.warning(resCode.text)
          return
        }
        if (this.listData[i].quantity > this.listData[i].product_amount) {
          this.moreNum = this.moreNum + 1
        }
        if (this.listData[i].quantity < this.listData[i].product_amount) {
          this.unmoreNum = this.unmoreNum + 1
        }
        this.listData[i].quantity = this.listData[i].quantity * 1
      }
      for (let i = 0; i < this.checkData.length; i++) {
        //let total=this.checkData[i].pickNum + this.checkData[i].pickNum_old
        //console.log(total);
        //当订单有两条一样商品每条20个总共40条可以第一次拣货10个然后第二次又对第一个拣货20个结果第一个商品拣货数量大于订单数量
        if (this.checkData[i].pickNum > this.checkData[i].quantity) {
        //if (total > this.checkData[i].quantity) {当我使用this.checkData[i].pickNum_old之后pickNum不会随着待出库明细变成0而变成0所以不用相加判断
          this.$Message.warning(`拣货数量大于订单数量`)
          return
        }
      }
      for (let i = 0; i < this.checkData.length; i++) {
        //let total=this.checkData[i].pickNum + this.checkData[i].pickNum_old
        if (this.checkData[i].pickNum < this.checkData[i].quantity) {
        //if (total < this.checkData[i].quantity) {
          this.modal1 = true
          return
        }
      }
      this.postStatus = true
    },
    lastPost() {
      if (!this.flag) return
      this.flag = false
      this.postFrom.warehouse_id = this.warehouse_id
      this.postFrom.line_info = this.listData
      this.postFrom.line_info.forEach((item, index) => {
        if (!item.production_date) {
          item.production_date = null
        }
      })
      this.$http
        .post(this.$api.warehousePicking, this.postFrom, true)
        .then(res => {
          this.flag = true
          this.$Message.success('提交成功')
          this.listData = []
          this.reload()
          this.postStatus = false
          this.postFrom.note = ''
          if(this.guanlianhang.selectType==1){
            let params={
              ...this.guanlianhang
            }
            delete params.selectType
            this.chooseSaleOrder(params)
          }else{
            let params={
              ...this.guanlianhang
            }
            delete params.selectType
            this.chooseReturnOrder(params)
          }
        })
        .catch(err => {
          this.flag = true
        })
      this.modal1 = false
    },
    // 确认删除
    sureDelete() {
      let a = this.listData.splice(this.deleteIndex, 1)
      this.deleteObjArr.push(a[0])
      this.deleteStatus = false
      this.$Message.success('删除成功')
      for (let i = 0; i < this.checkData.length; i++) {
        if (this.checkData[i].product_name == this.deleteObj.product_name && this.checkData[i].product_model_code == this.deleteObj.product_model_code && this.checkData[i].order_detail_id == this.deleteObj.order_detail_id) {
          this.$set(this.checkData[i], 'pickNum', this.checkData[i].pickNum - this.deleteObj.quantity)
        }
      }
    },
    // 删除
    deleteRow(row, index) {
      this.deleteObj = row
      this.deleteIndex = index
      this.deleteStatus = true
    },
    // 确认销售订单  查订单详情
    chooseSaleOrder(row) {
      this.$http
        .get(this.$api.saleOrderDetails, { order_code: row.order_code, picking_page: '1' }, true)
        .then(res => {
          this.customerName = res.data.customer_name
          let arr = res.data.order_detail
          for (let i = 0; i < arr.length; i++) {
            arr[i].model_name = arr[i].product_model_name
            arr[i].item_number = arr[i].product_item_number
            arr[i].unit = arr[i].product_unit
            arr[i].unit_price = arr[i].product_unit_price
            arr[i].quantity = arr[i].product_amount
            arr[i].picking_quantity = arr[i].picking_quantity || 0
            arr[i].pickNum = arr[i].picking_quantity
            arr[i].pickNum_old = arr[i].picking_quantity
          }
          this.sales = false
          this.listData = []
          this.checkData = JSON.parse(JSON.stringify(arr))
        })
        .then(() => {
          this.option_page = '15'
          this.type = 1
          this.queryOption()
        })
    },
    // 确认退货订单   查询订单详情
    chooseReturnOrder(row) {
      this.$http
        .get(this.$api.pruchaseReturnDetail, { id: row.id, picking_page: '1' }, true)
        .then(res => {
          let arr = res.data.purchase_return_line
          for (let i = 0; i < arr.length; i++) {
            arr[i].product_model_name = arr[i].model_name
            arr[i].product_item_number = arr[i].item_number
            arr[i].product_unit = arr[i].unit
            arr[i].product_unit_price = arr[i].unit_price
            arr[i].product_amount = arr[i].quantity
            arr[i].picking_quantity = arr[i].picking_quantity || 0
            arr[i].pickNum = arr[i].picking_quantity
            arr[i].pickNum_old = arr[i].picking_quantity
          }
          this.returnStatus = false
          this.listData = []
          this.checkData = JSON.parse(JSON.stringify(arr))
        })
        .then(() => {
          this.option_page = '18'
          this.type = 2
          this.queryOption()
        })
    },
    // 查询销售订单列表
    queeryModal1() {
      if (this.supplierIndex1 >= 0) {
        this.queryModal1.customer_id = this.clientNameList[this.supplierIndex1].customer_id
        this.queryModal1.customer_type = this.clientNameList[this.supplierIndex1].customer_type
      } else {
        this.queryModal1.customer_id = ''
        this.queryModal1.customer_type = ''
      }
      this.$http.get(this.$api.getSaleOrder, this.queryModal1, true).then(res => {
        this.total = res.total
        this.sales = true
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].creatTime = res.data[i].order_time ? this.$moment(res.data[i].order_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
        this.salesData = res.data
      })
    },
    // 查询退货通知单列表
    queeryModal2() {
      this.$http.get(this.$api.pruchaseReturnManage, this.queryModal2, true).then(res => {
        this.total2 = res.total
        this.returnStatus = true
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].creatTime = res.data[i].create_time ? this.$moment(res.data[i].create_time * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
        }
        this.returnData = res.data
      })
    },
    cancelQueryModal1(){
      this.modelList = []
      this.queryModal1.product_name = ''
      this.queryModal1.model_name = ''
    },
    // 产品名称改变
    productChange1(e) {
      this.queryModal1.model_name = ''
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    cancelQueryModal2(){
      this.modelList = []
      this.queryModal2.product_name = ''
      this.queryModal2.model_name = ''
    },
    // 产品名称改变
    productChange2(e) {
      this.queryModal2.model_name = ''
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(name) {
      this.$http.get(this.$api.productShow, {product_name: name,search_type:'1' }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 在弹窗里面点击查询
    queryData(num) {
      if (num == 1) {
        this.queryModal1.page = 1
        this.queeryModal1()
      } else if (num == 2) {
        this.queryModal2.page = 1
        this.queeryModal2()
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (e[0] == '' && e[1] == '') {
        if (num == 1) {
          this.queryModal1.order_time_begin = null
          this.queryModal1.order_time_end = null
        } else if (num == 2) {
          this.queryModal2.start_time = null
          this.queryModal2.end_time = null
        }
      } else {
        if (num == 1) {
          this.queryModal1.order_time_begin = e[0]
          this.queryModal1.order_time_end = e[1]
        } else if (num == 2) {
          this.queryModal2.start_time = e[0]
          this.queryModal2.end_time = e[1]
        }
      }
    },
    // 查询仓库列表
    queryStoreList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 获取客户名称
    querySupplierSelect() {
      this.$http.fetch(this.$api.supplierSelect, true).then(res => {
        this.supplierList = res.data
      })
    },
    // 关联
    openModal(num) {
      this.gltype = num
      this.hadSelectList = []
      this.qiegeFlag = false
      this.moreNum = 0
      this.unmoreNum = 0
      // 缓存的自动拣货需要修改
      this.qiegeFlag = false
      if (num == 1) {
        this.text = '销售订单产品列表'
        this.queeryModal1()
      } else if (num == 2) {
        this.text = '采购退货通知单产品列表'
        this.queeryModal2()
      }
    },
    changePage(e, num) {
      if (num == 1) {
        this.queryModal1.page = e
        this.queeryModal1()
      } else if (num == 2) {
        this.queryModal2.page = e
        this.queeryModal2()
      }
    },
    // 输入框改变
    changeIpt(e, name, index) {
      this.$set(this.listData[index], name, e.target.value.trim())
    },
    changeIpt1(e, name, index, product_model_code, product_name, order_detail_id) {
      this.moreNum = 0
      this.unmoreNum = 0
      this.$set(this.listData[index], name, e * 1)
      if (name == 'quantity') {
        let total = 0
        for (let i = 0; i < this.listData.length; i++) {
          if (this.listData[i].product_name == product_name && this.listData[i].product_model_code == product_model_code && this.listData[i].quantity && this.listData[i].order_detail_id == order_detail_id) {
            total = total * 1 + this.listData[i].quantity * 1
          }
        }
        for (let i = 0; i < this.checkData.length; i++) {
          if (this.checkData[i].product_name == product_name && this.checkData[i].product_model_code == product_model_code && this.checkData[i].order_detail_id == order_detail_id) {
            this.$set(this.checkData[i], 'pickNum', total * 1 + this.checkData[i].picking_quantity)
          }
        }
      }
    },
    changeIpt2(e, index) {
      this.$set(this.checkData2[index], 'picking_quantity', e * 1)
    },
  },
}
</script>

<style scoped lang="less">
/deep/ .modal {
  /deep/ .ivu-modal {
    width: 1050px !important;
  }
}
.checkList {
  margin-bottom: 30px;
  .picking {
    text-align: center;
    color: #ccc;
  }
}
.page {
  display: flex;
  flex-direction: column;
  color: #000;
  .pageTop {
    overflow-y: auto;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 22px 0;

    .item {
      width: 325px;
      text-align: right;

      font-size: 16px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }
  }

  .btm {
    flex: 1;
    display: flex;

    .btmRight {
      flex: 1;
      padding: 23px;
      border-top: 1px solid #e8eaec;
      display: flex;
      flex-direction: column;
      overflow: auto;
      p {
        margin-bottom: 23px;
        font-size: 18px;
        color: #525b6d;
      }

      .summary > span:first-child {
        margin-right: 30px;
      }

      .tipsDiv {
        margin: 20px 0;
        border: 1px solid #e8eaec;
        height: 230px;
        display: flex;
        .tipsFl {
          width: 210px;
          line-height: 230px;
          padding-left: 33px;
          border-right: 1px solid #e8eaec;
          background: #f8f8f9;
          color: #525b6d;
          font-size: 14px;
        }
        /deep/ .tipsFr {
          flex: 1;
          display: flex;
          padding: 8px 15px 8px 23px;
          .ivu-input-wrapper {
            height: 100% !important;
          }
          .ivu-input {
            height: 100% !important;
          }
        }
      }

      .button {
        height: 36px;
        line-height: 36px;
        text-align: right;
      }
    }
  }
}
.modal {
  /deep/ .ivu-modal-footer {
    border: 0;
  }
  .header {
    font-size: 18px;
    height: 25px;
    line-height: 25px;
    font-weight: 500;
    color: #333333;
  }
}
.marginRight60 {
  margin-right: 77px;
}
.iviewIptWidth150 {
  width: 150px !important;
}
.iviewIptWidth200 {
  width: 200px !important;
}
.marginRight23 {
  margin-right: 15px;
}
.totalNumber {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 15px 0px;
  padding: 0px 30px;
  box-sizing: border-box;
  font-size: 14px;
  .item {
    margin: 0px 15px;
    span {
      // color
    }
  }
}
</style>
